/* General Styles */
.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #6c2174;
}

.doctor-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical scrolling */
    margin-bottom: 20px;
    white-space: nowrap;
 /* Prevent wrapping */
}
.doctor-education {
    font-size: 9px;
   text-align: left;

}
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px; /* Space between logo and first card */
    margin-bottom: 20px; /* Space below the logo */
}

.logo {
    width: 200px; /* Adjust size for desktop */
    height: auto;
    border-radius: 10%;
    animation: fadeIn 2s ease-in-out;
}

/* Animation for logo */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.doctor-card {
    display: flex;
    align-items: center;
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 480px; /* Adjust width for desktop */
    margin: 0 10px; /* Space between cards */
    flex-shrink: 0; 
   margin-left: 21vh; /* Prevent wrapping */
    /* Prevent cards from shrinking */
}

.doctor-image {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    object-fit: cover;
    border: 3px solid purple;
}

.doctor-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.doctor-info h3 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

.profile-button {
    margin: 10px 0;
    padding: 8px 10px;
    background-color: #5d0575;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    margin-left: 9vh;
}

.profile-button:hover {
    background-color: #0056b3;
}

.doctor-education {
    margin: 5px 0;
    color: #000000;
    line-height: 1.5;
    max-height: 100px;
    overflow-y: auto;
    padding-right: 10px;
}

.social-links {
    display: flex;
    gap: 27px;
    margin-top: 10px;
}

.social-links a {
    font-size: 30px;
    transition: transform 0.3s, color 0.3s;
}

.social-links .facebook {
    color: #3b5998;
}

.social-links .twitter {
    color:#FF0000;;
}

.social-links .instagram {
    color: #e1306c;
}

.social-links .whatsapp {
    color: #25d366;
}

.social-links a:hover {
    transform: scale(1.2);
    color: #000;
}

.scrolling-text-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 10s linear infinite;
    font-size: 18px;
    color:  #FFD700;
}

/* Keyframes for scrolling text */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .logo-container {
        margin-right: 0; /* Remove right margin for mobile screens */
        margin-bottom: 10px; /* Adjust space below the logo */
    }

    .logo {
        width: 140px; /* Smaller logo size for mobile screens */
    }

    .doctor-cards {
         /* Stack cards vertically on mobile */
        overflow-x: hidden; /* Disable horizontal scrolling */
        overflow-y: auto; 
        width: 110%;
        gap: 0.5vh;
        
        /* Allow vertical scrolling */
    }

    .doctor-card {
        width: 49%; /* Full width of the container */
        margin: 5px 0;
         /* Space between cards */
    }

    .doctor-image {
        width: 90px; /* Smaller image size on mobile */
        height: 90px;
    }

    .doctor-info h3 {
        font-size: 10px;
        margin-left: -1vh; /* Adjust font size for mobile screens */
    }

    .profile-button {
        padding: 5px 5px;
        margin-left: -1.5vh;
        width: 110%;
        height: 4vh;
    }

    .social-links {
        gap: 1px; 
        margin-left: -1vh;
        margin-top: -0.5vh;
    }

    .social-links a {
        font-size: 20px; /* Adjust icon size for smaller screens */
    }
   
    .scrolling-text {
        font-size: 18px; /* Adjust font size for scrolling text on smaller screens */
    }
}
