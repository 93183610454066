/* Footer.css */
.footer {
  background-color: #6c306e; /* Dark lavender color */
  color: #FFFFFF; /* White text color for better contrast */
  padding: 40px 20px;
  text-align: left;
}

.footer-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.highlight-section {
  background-color: #d6bf3b; /* Gold background color for highlighting */
  color: #000000; /* Black text color for better contrast */
  padding: 3px;
  text-align: center;
  margin: 20px 0;
  font-size: 1.2rem;
}

.highlight-section p {
  margin: 5px 0;
  font-weight: bold;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h2 {
  margin-bottom: 10px;
  color: #FFFFFF; /* White text color */
}

.footer-section p,
.footer-section ul,
.footer-section a {
  color: #FFFFFF; /* White text color */
}

.footer-section a {
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #FFD700; /* Gold color for links on hover */
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.f, .mediaicons {
  font-size: 2em; /* Increase icon size */
  margin-right: 10px;
  color: #FFFFFF; /* White text color */
  transition: color 0.3s;
}

.f:hover, .mediaicons:hover {
  color: #FFD700; /* Gold color for social media icons on hover */
}

.addinfo {
  text-decoration: none;
  color: #FFFFFF; /* White text color */
}

.addinfo:hover {
  color: #FFD700; /* Gold color for address link on hover */
}

.copyright-section {
  text-align: center;
  margin-top: 20px;
  color: #FFFFFF; /* White text color */
}

.marketingagency {
  color: #FFD700; /* Gold color */
  text-decoration: none;
}

.marketingagency:hover {
  color: #FFA500; /* Darker gold hover color */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-wrap: nowrap; /* No wrapping, keep in single row */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
  }

  .highlight-section p {
    margin: 5px 0;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .footer-section {
    flex: 1 0 180px; /* Flex-grow, flex-shrink, and base size */
    margin: 5px; /* Reduce margin for more compact layout */
    padding: 5px; /* Add some padding to avoid text touching edges */
    text-align: left;
  }

  .footer-section h2 {
    font-size: 1em; /* Smaller font size for headings */
  }

  .footer-section ul li {
    font-size: 0.8em; /* Smaller font size for list items */
    margin: 2px 0; /* Reduce margin to make the list more compact */
  }

  .footer-section p,
  .footer-section ul,
  .footer-section a {
    font-size: 0.8em; /* Reduce font size for mobile */
  }

  .footer-section p {
    margin: 3px 0; /* Reduce margin between paragraphs */
  }

  .footer-section ul {
    padding: 0;
    margin: 3px 0; /* Reduce margin for ul to make it more compact */
  }

  .footer-section.quick-links ul li {
    font-size: 1em; /* Increase font size for Quick Links */
  }
}

@media (max-width: 480px) {
  .footer-container {
    flex-wrap: nowrap; /* No wrapping, keep in single row */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
  }

  .footer-section {
    flex: 1 0 150px; /* Flex-grow, flex-shrink, and base size */
    margin: 5px; /* Reduce margin for more compact layout */
    padding: 5px; /* Add some padding to avoid text touching edges */
    text-align: left;
  }

  .footer-section h2 {
    font-size: 0.9em; /* Smaller font size for headings */
  }

  .footer-section ul li {
    font-size: 0.7em; /* Smaller font size for list items */
    margin: 2px 0; /* Reduce margin to make the list more compact */
  }

  .mediaicons {
    font-size: 1.5em; /* Adjust icon size for mobile */
    margin: 5px 5px;
  }

  .footer-section p,
  .footer-section ul,
  .footer-section a {
    font-size: 0.7em; /* Reduce font size for mobile */
  }

  .footer-section p {
    margin: 3px 0; /* Reduce margin between paragraphs */
  }

  .footer-section ul {
    padding: 0;
    margin: 3px 0; /* Reduce margin for ul to make it more compact */
  }

  .footer-section.quick-links ul li {
    font-size: 1.2em;
    margin-left: 10%; /* Increase font size for Quick Links */
  }
}
