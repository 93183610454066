.blog9-container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .blog9-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog9-header h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .blog9-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blog9-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog9-text {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
  }
  
  .blog9-text ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .blog9-text ul li {
    margin-bottom: 10px;
  }
  