.blog-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.blog-main-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-section {
    margin-bottom: 40px;
}

.blog-section h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.blog-section p {
    font-size: 1em;
    line-height: 1.6;
}

.s {
    margin-left: 60vh;
}

.blog16-image {
    width: 40%; /* Adjusted width for larger screens */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease; /* Smooth transform transition */
    animation: fadeIn 2s ease-in-out; /* Fade-in animation */
    border: 7px solid purple;
}

@media (max-width: 768px) {
    .blog16-image {
        width: 100%;
        float: none;
        margin-right: 0;
    }
}
