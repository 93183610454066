@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes underlineExpand {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.hero-section {
  position: relative;
  overflow: hidden;
  height: 100vh; /* You can set this to a specific value if needed, like '80vh' */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f8ff;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #f0f8ff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  animation: fadeInUp 1s ease-in-out;
  max-width: 1200px;
  padding: 20px;
}

.hero-content h1,
.hero-content p {
  margin: 0;
}
.dr{
  font-weight: bold;
}
.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.5rem;
}

.hero-content1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure the image takes full height of the section */
  overflow: hidden;
  z-index: 1;
}

.hero-content1 img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  /* This will ensure the image covers the entire area */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
 
}

.logo{
  margin-top: -68vh;
  margin-left: -140vh;
 border: 4px solid purple !important;
 width: 38vh !important;
}
@media (max-width: 992px) {
  .hero-content1 {
    background-position: top center; /* Adjust position on medium screens */
  }

  .hero-content1 h1 {
    font-size: 3rem;
  }
  .dr{
    font-weight: bold;
  }
  

  .hero-content1 p {
    font-size: 1.2rem;
  }
  
}

@media (max-width: 768px) {
  .hero-content1 {
    background-position: center; /* Center the background image on smaller screens */
  }
  .dr{
    font-weight: bold;
  }

  .hero-content1 h1 {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }

  .hero-content1 p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
  
}

@media (max-width: 576px) {
  .hero-content1 h1 {
    font-size: 2rem; /* Further adjust font size for extra small screens */
  }
  .dr{
    font-weight: bold;
  }
  .hero-content1 p {
    font-size: 0.9rem; /* Adjust font size for extra small screens */
  }
  
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-overlay p {
  animation: fadeIn 1s ease-in-out;
}

@media (max-width: 992px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-content img {
    margin-bottom: 20px;
  }
  .dr{
    font-weight: bold;
  }
 
  
  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.2rem;
  }
  
}


@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 3rem; /* Adjust font size for heading on medium screens */
  }
  .hero-content p {
    font-size: 1.2rem; /* Adjust font size for paragraphs on medium screens */
  }
  .dr{
    font-weight: bold;
  }
}

@media (max-width: 992px) {
  .hero-content h1 {
    font-size: 2.5rem; /* Adjust font size for heading on smaller screens */
  }
  .hero-content p {
    font-size: 1rem; /* Adjust font size for paragraphs on smaller screens */
  }
  .dr{
    font-weight: bold;
  }
  .hero-content {
    color: #fefefe; /* White text color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Text shadow for better readability */
    margin-left: 550px;
    height: 50vh;
    width: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem; /* Adjust font size for heading on small screens */
  }
  .hero-content p {
    font-size: 0.9rem; /* Adjust font size for paragraphs on small screens */
  }
  .logo{
    margin-top: -17vh;
    margin-left: -51vh;
   border: 3px solid purple !important;
  width: 13vh !important;
  }
  
}

@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 1.5rem; /* Adjust font size for heading on extra small screens */
  }
  .hero-content p {
    font-size: 0.8rem; /* Adjust font size for paragraphs on extra small screens */
  }
  
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 1.2rem; /* Adjust font size for heading on very small screens */
  }
  .hero-content p {
    font-size: 0.7rem; /* Adjust font size for paragraphs on very small screens */
  }
}

.doctors-section {
  padding: 40px 20px; /* Padding around the doctors section */
  text-align: center;
  animation: fadeInUp 1s ease-in-out;
}

.meet-doctors {
  font-size: 2.5rem; /* Font size for section heading */
  color: #6c306e; /* Lavender color for heading */
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.meet-doctors::after {
  content: '';
  display: block;
  height: 3px;
  width: 100%;
  background-color: #6c306e; /* Lavender color for underline */
  position: absolute;
  bottom: -5px;
  left: 0;
  animation: underlineExpand 0.6s ease-in-out;
}

.doctor-profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Gap between doctor profiles */
}

.doctor-profile {
  max-width: 300px; /* Limit width of each profile */
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff; /* White background */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1s ease-in-out;
}

.doctor-profile:hover {
  transform: translateY(-5px); /* Lift profile on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.doctor-profile .doctor-image {
  width: 150px; /* Adjust image width */
  height: auto;
  border-radius: 50%; /* Make the image round */
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.doctor-profile .doctor-info {
  text-align: left;
}

.doctor-profile h3 {
  color: #00796b; /* Teal color for doctor names */
  margin-bottom: 8px; /* Space between name and details */
}

.doctor-profile p {
  color: #333; /* Darker text color for paragraphs */
  margin-bottom: 6px; /* Space between paragraphs */
}

.image-blog-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; 
 
  
}

.image-blog {
  max-width: 300px; /* Limit width of each image blog */
  border: none; /* Remove border */
  border-radius: 10px;
  background-color: #ffffff; /* White background */
  padding: 0; /* Remove padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1s ease-in-out;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.image-blog:hover {
  transform: translateY(-5px); /* Lift image blog on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.image-blog .blog-image {
  width: 100%; /* Full width of the container */
  height: 200px; /* Set a fixed height for consistency */
  object-fit: cover; /* Cover the entire area */
}

.blog-info {
  text-align: left;
  padding: 10px 15px; /* Add padding for spacing */
}

.blog-info h3 {
  color: #00796b; /* Teal color for titles */
  margin-bottom: 8px; /* Space between title and details */
  font-family: 'Georgia', serif; /* Font style for title */
  font-size: 1.3rem; /* Font size for title */
  text-decoration: none; /* Remove underline */
}

.blog-info p {
  color: #333; /* Darker text color for paragraphs */
  margin-bottom: 6px; /* Space between paragraphs */
  font-family: 'Arial', sans-serif; /* Font style for paragraphs */
  font-size: 1rem; /* Font size for paragraphs */
  line-height: 1.6; /* Line height for better readability */
  text-decoration: none; /* Remove underline */
}
.image-blog a {
  text-decoration: none;
  color: inherit; /* Keep the link color the same as the surrounding text */
}
@media (max-width: 600px) {
  .doctor-profile {
    margin: 10px;
  }

  .doctor-image {
    width: 120px;
    height: 120px;
  }

  .doctor-info {
    margin-top: 5px;
  }

  .dr {
    font-size: 3rem;
  }

  .M {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .doctor-profile {
    margin: 5px;
  }

  .doctor-image {
    width: 100px;
    height: 100px;
  }

  .doctor-info {
    margin-top: 5px;
  }

  .dr {
    font-size: 3rem;
  }

  .M {
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .doctor-profile-container,
  .image-blog-section {
    flex-direction: column;
    align-items: center;
  }

  .hero-section {
    height: 70vw;
    /* Let the hero section height adjust based on content */
  }

  .hero-content {
    margin: 0;
    margin-left: 150px; /* Remove margin on smaller screens */
  }

  .meet-doctors {
    font-size: 1.9rem; /* Font size for section heading */
    color: #6c306e; /* Lavender color for heading */
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
.o{
  text-decoration: none !important;
}