/* Background container */
.econtainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(images/p1.avif); /* Add your background image here */
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding: 20px; /* Add some padding to avoid content sticking to edges */
  font-family: 'Roboto', sans-serif;
 
}

.econtainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better contrast */
  z-index: -1;
  opacity: 0.7;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

/* Form container */
.form-container {
  flex: 1;
  max-width: 70vh;
  padding: 30px;
  background: linear-gradient(145deg, rgba(255,255,255,0.9), rgba(255,255,255,0.95));
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: slideInFromLeft 1s ease-out;
  transition: transform 0.3s, box-shadow 0.3s;
 
  
}

.form-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
}

.fhead {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  position: relative;
}

.fhead::after {
  content: '';
  width: 50px;
  height: 3px;
  background-color:  #6c306e;
  display: block;
  margin: 10px auto 0;
}

form {
  width: 60vh;
 
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  background: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color:  #6c306e;
  box-shadow: 0 0 8px rgba(108, 108, 253, 0.2);
  outline: none;
}

textarea {
  resize: vertical;
}

.ebt-container {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: #6c306e;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s;
}

.ebt-container:hover {
  background-color: #6c306e;
  transform: translateY(-5px);
}

/* Icon before input */
.form-group i {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  padding-left: 40px;
}

/* Map container */
.map-container {
  flex: 1;
  width: 200vh;
  height: 450px;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: slideInFromRight 1s ease-out;
  margin-top: 10%;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
}
.map{
  width:200vh;
}

.map-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
}

.map-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s;
  width: 200vh;
 
}

.map-overlay:hover {
  background: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media only screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .form-container, .map-container {
    max-width: 100%;
    margin-bottom: 20px;
    
  }

  .map-container {
    height: 400px;
    width: 170vw;
  }
  form {
    width: 100%;
    height: 145vw;
  }
  .form-group {
    margin-bottom: 10px;
    position: relative;
  }
  .map-container {
    flex: 1;
    max-width: 90vw;
    height: 450px;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    animation: slideInFromRight 1s ease-out;
    margin-top: 10%;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
  }
   
  }
  
  

