/* Services.css */

/* Apply animation to cards */
.scard {
  width: 25vw; 
  height: 37vw; /* Default height for cards on larger screens */
  margin: 3vw; /* Add margin to create distance between cards */
  display: flex; /* Use flexbox for vertical centering */
  flex-direction: column; /* Arrange content vertically */
  justify-content: center; /* Center content vertically */
  border: 1px solid #ccc; /* Add border to the cards */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the cards */
  animation: slideInFromTop 0.9s ease-in-out forwards; /* Apply animation */
}

/* Additional styles for MultiCarousel container */
.container-with-dots {
  width: 100%; /* Set container width to 100% to ensure cards don't overflow */
  margin-bottom: 5vw; /* Add some bottom margin to create space */
}

.img {
  height: 10vw;
  object-fit: cover;
}

.service-title {
  color: #333; /* Set color for the service title */
  margin-bottom: 10px; /* Add some bottom margin to separate title and info */
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}

/* Style for service information */
.service-info {
  color: #666; /* Set color for the service information */
  font-size: 1.1rem; 
  font-weight: normal;
  text-align: center;
}

.serviceshead {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin: 30px auto; /* Center the serviceshead horizontally and add top margin */
  position: relative; /* Ensure the relative positioning */
  animation: slideInFromTop 1s ease forwards; /* Apply animation */
}

.serviceshead::after {
  content: '';
  position: absolute;
  bottom: 0.2vh; /* Adjust the distance of the line from the heading */
  left: 50%; /* Position the line at the center */
  width: 15vw; /* Responsive width for the line */
  height: 0.5vh; /* Set the height of the line */
  background-color: #6c306e; /* Set the color of the line */
  transform: translateX(-50%); /* Center the line horizontally */
}

.service-info {
  font-size: 1rem;
}

.img {
  height: 15vw;
}

.sbutton {
  margin-left: 15vh; /* Center the button on smaller screens */
  margin-right: 10vh;
  background-color: #6c306e;
  border: 1px solid #6c306e;
}

/* Responsive design */
@media screen and (max-width: 991px) {
  .scard {
    width: 45vh; /* Adjust card width for smaller screens */
    height: 55vh; /* Adjust card height for smaller screens */
    margin: 0 auto 20px; /* Center cards and add bottom margin */
  }

  .img {
    height: 20vh; /* Adjust image height for smaller screens */
  }

  .serviceshead {
    font-size: 2rem;
    margin: 4vh auto; /* Center the serviceshead horizontally and add top margin */
  }

  .serviceshead::after {
    width: 15vh; /* Adjust width of the line */
    height: 0.3vh; /* Adjust height of the line */
  }

  .service-info {
    font-size: 1rem;
  }

  .sbutton {
    margin-left: 15vh; /* Center the button on smaller screens */
    margin-right: 10vh;
  }
}
