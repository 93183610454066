/* Navbar styling */
.Navbar {
  background-color: #6c306e; /* Dark lavender color */
  padding-top: 15px; /* Increased top padding */
  padding-bottom: 15px; /* Increased bottom padding */
  transition: background-color 0.3s; /* Smooth color transition */
  position: relative; /* Relative positioning for ::after effect */
}

/* Navbar ::after effect for animation */
.Navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: #FFD700; /* Gold color */
  transition: width 0.3s ease-in-out; /* Smooth width transition */
}

/* Expanded state styling */
.Navbar.expanded::after {
  width: 100%; /* Full width when expanded */
}

/* Logo styling */


/* Hospital name styling */
.header {
  color: #FFFFFF; /* White text color */
  font-size: 1.5rem; /* Larger font size */
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: opacity 0.3s ease-in-out; /* Smooth opacity transition */
}

.navbar-submenu .nav {
  display: flex !important; 
  color: #FFFFFF;
  text-decoration: none;

}
.routes{
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
    font-size: 1.5rem;
   
  
}
.hospital-name {
  font-size: 2.5rem; /* Larger font size */
  font-family: 'Arial', sans-serif; /* Use a modern, clean font */
  font-weight: 700; /* Bold font weight */
  color: #FFD700; /* Gold color for text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  letter-spacing: 1px; /* Increase letter spacing */
margin-left: -7vh;
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out; /* Smooth transition */
}

.hospital-name:hover {
  color: #FFFFFF; /* Change color on hover */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7); /* Enhanced shadow on hover */
}
/* Media query for smaller screens */
@media screen and (max-width: 991px) {
  /* Adjustments for smaller screens */
  .header {
    font-size: 1.2rem; /* Smaller font size */
    text-align: center;
  }


  .logo-img {
    width: 40px; /* Smaller logo size */
    height: auto; /* Maintain aspect ratio */
  }

  .hospital-name {
    font-size: 2rem; /* Larger font size */
    font-family: 'Arial', sans-serif; /* Use a modern, clean font */
    font-weight: 700; /* Bold font weight */
    color: #FFD700; /* Gold color for text */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    letter-spacing: 1px; /* Increase letter spacing */
    margin-left: 1vh;
    transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out; /* Smooth transition */
  }

  .navbar-submenu .nav  {
   display: flex;
   flex-direction: row;
   
  
  }
/* Navrouter.css */
.routes {
  background-color: white;
  padding: 1px; /* Increase padding for better click/tap area */
  color: #6c306e;
  margin: 2px;
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
  display: inline-block; /* Ensures inline-block for animation to work */
  animation: bounce 0.5s infinite; 
  font-size: 1rem;
}

.routes:hover {
  animation: bounce 0.5s infinite; /* Apply bounce animation on hover */
  background-color: rgb(55, 237, 95);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0); /* Start and end with no vertical movement */
  }
  50% {
    transform: translateY(-5px); /* Move upwards by 5 pixels */
  }
}

  .navbar-brand {
    width: 100%; /* Full width */
    text-align: center; /* Center-align text */
    white-space: normal; /* Allow wrapping */
    overflow: hidden; /* Hide overflow */
  }
}

/* Inline-block display for navbar brand span */
.navbar-brand span {
  display: inline-block;
}
