/* General Container */
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    background: linear-gradient(to right, #f7f7f7, #e3e3e3);
  }
  
  /* Main Heading */
  .about-containerhead {
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
    position: relative;
    margin-bottom: 2vw;
    color: #222;
  }
  
  .about-containerhead::after {
    content: '';
    position: absolute;
    bottom: -0.5vw;
    left: 50%;
    width: 9vw;
    height: 0.2vw;
    background-color: #6c306e;
    transform: translateX(-50%);
    animation: underlinePulse 2s infinite;
  }
  
  @keyframes underlinePulse {
    0% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: translateX(-50%) scale(1);
    }
  }
  
  /* About Content */
  .about-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 3vw;
  }
  
  /* About Column (Each Doctor's Information) */
  .about-column {
    width: 45%;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 2vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .about-column:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  /* Doctor Photo Wrapper */
  .doctor-photo-wrapper {
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  /* Doctor Photo */
  .doctor-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .doctor-photo:hover {
    transform: scale(1.05);
  }
  
  /* Doctor Information */
  .doctor-info {
    text-align: center;
    margin-top: 1.5vw;
  }
  
  /* Doctor Name */
  .doctor-name {
    font-size: 2rem;
    font-weight: bold;
    color: #6c306e;
  }
  
  /* Doctor Title */
  .doctor-title {
    font-size: 1.5rem;
    margin-bottom: 1.5vw;
  }
  
  /* About Paragraphs */
  .about-containerpara {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5vw;
    color: #555;
  }
  
  /* Services Container */
  .services-container {
    padding: 2vw;
    background: #6c306e;
    background-size: cover;
    background-position: center;
    margin-top: 3vw;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
  }
  
  /* Main Heading for Services */
  .main-heading {
    text-align: center;
    margin-bottom: 3vw;
    font-size: 2.5rem;
  }
  
  /* Rows for Service Cards */
  .row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  /* Individual Service Card */
  .service-card {
    flex: 1 1 40%;
    margin: 1vw;
    background: rgba(255, 255, 255, 0.9);
    padding: 2vw;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: fadeInUp 1s ease-in-out forwards;
    opacity: 0;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Icon Container */
  .Aicon {
    font-size: 2rem;
    margin-bottom: 1.5vw;
    color: #6c306e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Service Card Heading */
  .service-cardhead {
    color: #6c306e;
    margin-bottom: 1vw;
    text-align: center;
    font-size: 1.5rem;
  }
  
  /* Service Card Paragraph */
  .service-cardpara {
    color: #333;
    line-height: 1.6;
    font-size: 1rem;
  }
  
  /* Media Queries for Responsive Design */
  @media screen and (max-width: 991px) {
    .about-containerhead {
      font-size: 2rem;
      margin-bottom: 2vh;
      margin-top: 1vh;
    }
  
    .Aicon {
      font-size: 2rem;
    }
  
    .service-cardhead {
      font-size: 1.2rem;
    }
  
    .service-cardpara {
      font-size: 0.9rem;
    }
  
    .about-content {
      flex-direction: column;
      align-items: center;
    }
  
    .about-column {
      width: 100%;
      margin-bottom: 3vw;
    }
  
    .doctor-info {
      margin-top: 3vw;
    }
  
    .doctor-photo-wrapper {
      width: 70%;
      max-width: 100%;
    }
  
    .services-container {
      margin-top: 3vh;
    }
  
    .main-heading {
      font-size: 1.5rem;
      margin-bottom: 3vh;
    }
  
    .row {
      flex-direction: column;
      align-items: center;
    }
  
    .service-card {
      flex: 1 0 80vw;
      width: 100%;
      margin: 2vh 0;
    }
  }
  