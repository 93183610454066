.blog18-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .blog18-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog18-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .blog18-text {
    font-size: 16px;
    line-height: 1.6;
  }
  