/* Reset and basic styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Blog page container */
.blog-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

/* Main blog image */
.blog-main-image {
  width: 80%; /* Adjust the width as needed */
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  animation: zoomIn 0.6s ease-in-out;
  border: 2px solid purple;
  margin-left: 10%; /* Center align on larger screens */
}

/* Sections containing headings and content */
.blog-section {
  margin-bottom: 40px;
}

/* Headings inside sections */
.blog-section h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #971d8f;
  position: relative;
}

/* Paragraphs inside sections */
.blog-section p {
  font-size: 1.1em;
  line-height: 1.8;
  text-align: justify;
  animation: fadeInUp 1s ease-in-out;
}

/* Sub images within sections */
.blog-sub-image {
  width: 50%;
  height: auto;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  animation: slideInLeft 0.6s ease-in-out;
  border: 2px solid purple;
  background-color: #5b1251;
  color: #f9f9f9;
  text-align: center;
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .blog-main-image {
    width: 100%; /* Adjust to full width on smaller screens */
    margin-left: 0; /* Reset margin for smaller screens */
  }
}
