.sectionStyle {
  font-family: 'Poppins', sans-serif;
  background-color: #6c306e;
  padding: 2vw;
  color: white;
}

.headingStyle {
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2vw;
  position: relative;
  color: white;
}

.doctor-name{
  margin-left: 30vh;
}


.underlineStyle {
  content: '';
  position: absolute;
  bottom: -0.5vw;
  left: 50%;
  width: 9vw;
  height: 0.2vw;
  background-color: #6c306e;
  transform: translateX(-50%);
  animation: underlinePulse 2s infinite;
}

.profileContainerStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 3vw;
}

.profileStyle {
  width: 45%;
  margin-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: fadeInUp 1s ease-in-out forwards;
}
.about-containerpara{
  text-align: start;
  font-size:0.7rem;
  font-weight: bold;
 
}

@media (max-width: 768px) {
  .profileStyle {
    width: 90%;
  }
  .doctor-name{
    margin-left: 20vh;
  }
  
}

.fadeInUpAnimation {
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.serviceContainerStyle {
  padding: 2vw;
  background-color: #a34ba6;
  margin-top: 3vw;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
}
.doctor-name{
  margin-left: 30vh;
}

.mainHeadingStyle {
  text-align: center;
  margin-bottom: 3vw;
  font-size: 2.5rem;
}

.rowStyle {
  display: flex;
  justify-content: space-around;
  margin-top: 3vw;
}

@media (max-width: 768px) {
  .rowStyle {
    flex-wrap: wrap;
  }
}

.serviceCardStyle {
  flex: 1 1 45%;
  margin: 1vw;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2vw;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in-out forwards;
}
.doctor-name{
  margin-left: 30vh;
}

@media (max-width: 768px) {
  .serviceCardStyle {
    flex: 1 1 100%;
  }
  .mainHeadingStyle {
    font-size: 1.5rem;
  }
  .service-cardpara {
    text-align: center;
  }
  .headingStyle {
    font-size: 2.2rem;
    
  }
  .doctor-name{
    margin-left: 30vh;
  }
  
}


.doctor-photo-wrapper {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.doctor-photo-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .doctor-photo-wrapper {
    height: 90vw; /* Adjust height for smaller screens */
  }
}
