/* Reset and basic styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Blog page container */
.blog-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Content wrapper for image and additional info */
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
}

/* Main image */
.blog-image {
  width: 60%; /* Adjusted width for larger screens */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease; /* Smooth transform transition */
  animation: fadeIn 2s ease-in-out; /* Fade-in animation */
}

/* Hover effect on image */
.blog-image:hover {
  transform: scale(1.05); /* Enlarge image on hover */
}

/* Additional info */
.additional-info {
  text-align: center;
}

.additional-info h3 {
  font-size: 1.5em;
  color: #3464b2; /* Example color */
  margin-bottom: 10px;
}

.additional-info p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 8px; /* Adjust spacing between paragraphs */
}
.c {
  margin-left: 0; /* Reset heading margin */
  text-align: center;
  margin-bottom: -2vh; 
  border: 2px solid rgb(96, 9, 96);
  background-color: rgb(118, 37, 118);
  color: aliceblue;/* Center align heading on smaller screens */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .blog-image {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
  .c {
    margin-left: 0; /* Reset heading margin */
    text-align: center;
    margin-bottom: -3vh; /* Center align heading on smaller screens */
  }
}
