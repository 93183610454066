
.doctor-profile-container {
  display: flex;
  justify-content: center;
  padding: 3vw 3vw; 
  background-image: url(./images/profilebg.jpg);
  background-size: cover;
  background-position: center;
}
.doctor-photo-card {
  width: 25vw;
  height:40vw;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  background-color: white;
  border: 2px solid #807e7e;
}

.doctor-nameh2{
  text-align: center;
  font-weight: bolder;
  color: black;
  margin-top: 1vw;
}
.doctor-namep{
  text-align: center;
  color: #514cf8;
  font-size: 1.2rem;
  font-weight: bold;
}
/* Hover effect on doctor photo card */
.doctor-photo-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Adjust box-shadow */
}
.doctor-photo-card img {
  width: 100%;
  height: auto;
  display: block; /* Remove extra space under the image */
}

/* Doctor name and details */
.doctor-info-card {
  width: 30vw;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-left: 1.5vw;
}

/* Doctor info card headings */
.doctor-info-card h1 {
  color:black;
  font-size: 2.7rem;
  font-weight: bold;
  margin-bottom: 1vw;
  text-align: center;
}

.doctor-info-cardp {
  text-align: center;
  font-size: 1.5rem;
  color: rgb(111, 111, 241);
  margin-bottom: 10px;
  font-weight: bold;
}

/* Additional info items */
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}
 .info-itemp .icon strong  span{
  display: inline-block;
  align-items: center;
}

 .icon {
  font-size: 1.5rem;
  color: rgb(128, 128, 250);
  margin-top: 0.7vw;
  margin-right: 1vw;
}
.info-itemp {
  font-size: 1rem;
  margin-left: 2vw; 
 
  color: #201b1d;
}
.info-itempspan{
  margin-left: 3vw;
}
.doctor-button{
  color:white;
  font-weight: bold;
  background-color: rgb(101, 101, 238);
  border: none;
  font-size: 1.5rem;
  margin-left: 9vw;
  margin-top: 2vw;
}
.visitordoctorhead{
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  margin-top: 2vw;
  position: relative;
  animation: slideInFromTop 1s ease forwards; 
}
.visitordoctorhead::after{
  content: '';
  position: absolute;
  bottom: -0.5vw; /* Adjust the distance of the line from the heading */
  left: 50%; /* Position the line at the center */
  width: 13vw; /* Set width relative to viewport width */
  height: 0.2vw; /* Set height relative to viewport width */
  background-color: #3c74ec; /* Set the color of the line */
  transform: translateX(-50%); /* Center the line horizontally */
}

.dcard-container{
  width: 100%;
}
/* Styles for card */
.dcard {
 margin: 1.5vw;
 height: 25vw;
 width: 25vw;
}

/* Styles for card body */
.dcard-body {
  padding: 20px; /* Adjust padding as needed */
}

/* Styles for logo container */
.logo-container {
  text-align: center;
  margin-bottom: 10px; /* Adjust margin as needed */
}

/* Styles for logo */
.dinfologo {
  width: 6vw; /* Adjust logo size as needed */
}

/* Styles for card title */
.dcard-title {
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: bold;
  margin-bottom: 5px; /* Adjust margin as needed */
  text-align: center;
}

/* Styles for card subtitle */
.dcard-subtitle {
  font-size: 1rem; /* Adjust font size as needed */
  color: #888; /* Adjust color as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
  text-align: center;
}

/* Styles for card text */
.dcard-text {
  font-size: 1rem; /* Adjust font size as needed */
  text-align: center;
}

/* Styles for appointment button */
.dbutton {
  margin-top:1vw; /* Adjust margin as needed */
  margin-left: 6vw;
}
.info-itempspan{
  margin-left: 0;
  margin-top: -10px; 
}

/* Responsive Styling */
@media screen and (max-width: 991px) {
  .doctor-profile-container {
    background-image: url(./images/profilebg.jpg);
    background-size: cover;
    background-position: center;
  }
  .doctor-photo-card {
    width: 28vh;
    height:38vh;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    background-color: white;
    border: 2px solid #807e7e;
  }
  .doctor-nameh2{
    text-align: center;
    font-weight: bolder;
    color: black;
    font-size: 1rem;
    margin-top: 1vh;
  }
  .doctor-namep{
    text-align: center;
    color: rgb(20, 108, 208);
    font-size: 0.7rem;
    font-weight: bold;
  }
  /* Hover effect on doctor photo card */
  .doctor-photo-card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Adjust box-shadow */
  }
  .doctor-photo-card img {
    width: 100%;
    height:70% ;
    object-fit: cover;
    display: block; /* Remove extra space under the image */
  }
  
  /* Doctor name and details */
  .doctor-info-card {
    width: 30vh;
    padding: 2vh;
    background-color: white;
    border-radius: 10px;
    margin-left: 1.5vw;
  }
 
  /* Doctor info card headings */
  .doctor-info-cardh1 {
    color: black!important;
    font-size: 1rem!important;
    font-weight: bold;
    margin-bottom: 2vw;
    text-align: center;
  }
  
  .doctor-info-cardp {
    text-align: center;
    font-size: 0.7rem;
    color: rgb(85, 85, 248);
    font-weight: bold;
    margin-bottom: 2vh;
  }
  /* Additional info items */
  .info-item {
    display: flex;
    align-items: center;
    margin-top: -1.2vh;
  }
  .info-itemp .info-itempspan .icon strong span{
    display: inline-flex;
    align-items: center;
    
  }
   .icon {
    font-size: 0.6rem;
    color: rgb(86, 86, 251);
    margin-top: 0.2vh;
  }
  .infotitle{
     margin-left:0.5vh;
    
  }
  .info-itemp {
    font-size: 0.5rem;
    color: #201b1d;
    margin-left: 1vh;

   
  }
  .doctor-button{
    color:white;
    font-weight: bold;
    background-color:rgb(101, 101, 248) ;
    border: none;
    font-size: 0.7rem;
    margin-left: 10vw;
    margin-top: 2vw;
    height: 3.5vh;
    width: 11vh;
    
  }
  .visitordoctorhead{
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    margin-top: 2vh;
    position: relative;
  animation: slideInFromTop 1s ease forwards; 
  }
  .visitordoctorhead::after{
    content: '';
    position: absolute;
    bottom: -0.5vw; /* Adjust the distance of the line from the heading */
    left: 50%; /* Position the line at the center */
    width: 13vh; /* Set width relative to viewport width */
    height: 0.2vh; /* Set height relative to viewport width */
    background-color: #235cd8; /* Set the color of the line */
    transform: translateX(-50%); /* Center the line horizontally */
  }
  
  .dcard-container{
    width: 100%;
  }
  /* Styles for card */
  .dcard {
   margin-left: 3vh;
   height:43vh;
   width: 40vh;
   margin-top: 4vh;
  }
  
  /* Styles for card body */
  .dcard-body {
    padding: 20px; /* Adjust padding as needed */
  }
  
  /* Styles for logo container */
  .logo-container {
    text-align: center;
  }
  
  /* Styles for logo */
  .dinfologo {
    width: 9vh; /* Adjust logo size as needed */
    height: 9vh;
  }
  
  /* Styles for card title */
  .dcard-title {
    font-size: 1.5rem; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 5px; /* Adjust margin as needed */
    text-align: center;
  }
  
  /* Styles for card subtitle */
  .dcard-subtitle {
    font-size: 1rem; /* Adjust font size as needed */
    color: #888; /* Adjust color as needed */
    margin-bottom: 10px; /* Adjust margin as needed */
    text-align: center;
  }
  
  /* Styles for card text */
  .dcard-text {
    font-size: 1rem; /* Adjust font size as needed */
    text-align: center;
  }
  
  /* Styles for appointment button */
  .dbutton {
    margin-top:1vw; /* Adjust margin as needed */
    margin-left: 8vh;
  }
}
