body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.blog-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.blog-main-image {
  width: 80%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  animation: zoomIn 0.6s ease-in-out;
  margin-left: 20vh;
  border: 2px solid purple;
  
}

.blog-section {
  margin-bottom: 40px;
}

.blog-section h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #0056b3;
  position: relative;
}

.blog-section p {
  font-size: 1.1em;
  line-height: 1.8;
  text-align: justify;
  animation: fadeInUp 1s ease-in-out;
}

.blog-sub-image {
  width: 50%;
  height: auto;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  animation: slideInLeft 0.6s ease-in-out;
}

@media (max-width: 768px) {
  .blog-sub-image {
      width: 100%;
      float: none;
      margin-right: 0;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes zoomIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInLeft {
  from { transform: translateX(-100px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

/* Extra styling for headings */
.blog-section h3::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background: #0056b3;
  margin-top: 10px;
  border-radius: 2px;
  animation: growWidth 1s ease-in-out;
}
.s{
  margin-left: 60vh;
}

@keyframes growWidth {
  from { width: 0; }
  to { width: 50px; }
}
