.testimonial-container {
  background: #f9f9f9;
  padding: 50px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonialh2 {
  text-align: center;
  font-size: 2.5rem;
  color: #00275B;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #4D088E;
  margin-left: 570px;
}

.testimonialp {
  text-align: center;
  font-size: 1.2rem;
  color: #4D088E;
  margin-bottom: 2rem;
  font-style: italic;
  padding: 0 10px;
}

.testimonial-videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 20px;
  justify-items: center;
}

.testimonial-video {
  width: 100%;
  max-width: 560px;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background: #fff;
  animation: zoomIn 0.6s ease-out; /* Zoom In Animation */
}

.testimonial-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .testimonialh2 {
    font-size: 1.9rem;
    margin-left: 75px;
  }

  .testimonialp {
    font-size: 1rem;
  }

  .testimonial-videos {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr)); /* Auto rows */
  }

  .testimonial-video {
    padding-top: 60%; /* Adjusted for mobile */
    animation: fadeIn 1s ease-in; /* Fade In Animation for Mobile */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .testimonialp {
    font-size: 1.1rem;
  }

  .testimonial-videos {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for tablets */
    grid-template-rows: repeat(2, auto); /* 2 rows */
  }

  .testimonial-video {
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    animation: slideUp 0.7s ease-out; /* Slide Up Animation for Tablets */
  }
}

/* Hover effect */
.testimonial-video:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
